import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1cac87a4 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _6ec3823c = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _27f5324a = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _62d00e7e = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _3ee49761 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _685a1f8f = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _6eaa7744 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _294a5b80 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _30ca2f1b = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _31a46ddf = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _1d6ad98e = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _191260f8 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _9a18effe = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _685e3501 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _168e4385 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _faab9f40 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _0765e16a = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _dcbf4f40 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _5918176a = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _98fe3d8a = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _1cac87a4,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _6ec3823c,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _27f5324a,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _62d00e7e,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _3ee49761,
    name: "receipt"
  }, {
    path: "/search",
    component: _685a1f8f,
    name: "search"
  }, {
    path: "/search-booking",
    component: _6eaa7744,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _294a5b80,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _30ca2f1b,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _31a46ddf,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _1d6ad98e,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _191260f8,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _9a18effe,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _685e3501,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _168e4385,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _faab9f40,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _0765e16a,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _dcbf4f40,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _5918176a,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _98fe3d8a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
